<template>
  <div
    class="tab-pane fade show active"
    id="about_tab"
    role="tabpanel"
    aria-labelledby="about-tab"
  >
    <div class="about_tab_content">
      <div class="container">
        <component edit :product="product" :is="type.editDetail"></component>
      </div>
    </div>

    <!--about_tab_content end-->
  </div>
</template>

<script>
export default {
  name: "ProductDetail",
  components: {
    NewCourse: () => import("../NewProduct/NewCourse.vue"),
    NewBook: () => import("../NewProduct/NewEBook.vue"),
    NewBlog: () => import("../NewProduct/NewBlog.vue"),
    NewEvent: () => import("../NewProduct/NewEvent.vue"),
    NewSeance: () => import("../NewProduct/NewSeance.vue"),
  },
  props: {
    type: {
      default: () => {},
    },
    product: {
      default: () => {},
    },
    productIndex: {
      default: 0,
    },
  },
};
</script>

<style></style>
